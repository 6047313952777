angular.module('enervexSalesappApp').factory('ModalUtil', function ModalUtil(_, $uibModal) {
	return {
		modalBuilder: function(parentElement, templateUrl, controllerName, resolveComponents, $scope, size, backdropClass) {
			var parentElem = angular.element(document.querySelector(parentElement));
			// pass things through to the other controller with the resolve method
			var modalInstance = $uibModal.open({
				templateUrl: templateUrl,
				scope: $scope,
				appendTo: parentElem,
				controller: controllerName,
				size: size,
				backdrop: 'static',
				backdropClass: backdropClass,
				resolve: resolveComponents
			});
			modalInstance.rendered.then(function() {
				$(".modal-content").draggable();
				var newHeight = $(window).height() * .7;
				$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
			})
			return modalInstance.result.then(function(result) {
				return {
					modalInstance: modalInstance,
					result: result
				}
			}).catch(function() {
				$scope._toSelectMode()
			})
		}
	}
})
